import './index.css';
import './App.css';
import Contact from './components/Contact';
import Testimonials from './components/Testimonials';
import Legacy from './components/Legacy';
import Footer from './components/Footer';
import Topbar from './components/Topbar';
import VideoPlayer from './components/VideoPlayer';

// Render a YouTube video player

function App() {
  return (
    <div className="justify-evenly">
      <Topbar/>
      <VideoPlayer/>
      <Testimonials/>
      <Contact/>
      <Legacy/>
      <Footer/>
    </div>
  );
}

export default App;
